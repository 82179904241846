import { useInView } from "react-intersection-observer";
import React, { useEffect } from "react";

function Experience() {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    // Update the document title using the browser API
    if (inView === true) {
      document.getElementById("experience").classList.remove("hidden");
      document
        .getElementById("experience")
        .classList.add("animate__fadeInLeft");
    }
  });
  return (
    <>
      <section
        ref={ref}
        className="experience hidden animate__animated "
        id="experience"
      >
        <h1 class="section-header has-text-weight-light">⠀Experience⠀</h1>
        <div className="columns">
          <div className="column is-3 ">
            <p class="is-size-4 has-text-weight-light is-size-5-mobile">
              Graphic Designer
            </p>
            <p class="">AlteaDesign</p>
            <p class=" is-size-7">2020-2023</p>
          </div>
          <div className="column is-9">
            <ul>
              <li>
                Creating a wide range of design assets such as branding kits,
                social media templates, fonts, resumes, icons, planners, and
                more.
              </li>
              <li>
                Create guides to assist clients with varying levels of design
                expertise in utilizing the assets
              </li>
              <li>
                Handle communication with clients to understand their needs and
                ensure their desired outcome is achieved in the final design
              </li>
            </ul>
          </div>
        </div>
        <div className="columns">
          <div className="column is-3">
            <p class="is-size-4 has-text-weight-light is-size-5-mobile">
              IT Support Specialist
            </p>
            <p class="">PT. JKI Indonesia</p>
            <p class=" is-size-7">2017-2020</p>
          </div>
          <div className="column is-9">
            <ul>
              <li>
                Automate and accelerate various administrative processes, while
                ensuring compliance with government regulations.
              </li>
              <li>
                Solve various technical issues faced by customers and field
                workers, and process their feedback to improve our system.
              </li>
              <li>Create and manage the company's regional website.</li>
            </ul>
          </div>{" "}
        </div>
      </section>
    </>
  );
}

export default Experience;
