import picture from "../assets/my-picture.png";
import { useInView } from "react-intersection-observer";
import React, { useEffect } from "react";
function About() {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    // Update the document title using the browser API
    if (inView === true) {
      document.getElementById("about").classList.remove("hidden");
      document.getElementById("about").classList.add("animate__fadeInLeft");
    }
  });
  return (
    <>
      <section ref={ref} className="about-wrapper">
        <div className="about animate__animated hidden" id="about">
          {" "}
          <h1 class="section-header has-text-weight-light  ">⠀About⠀</h1>
          <div class="columns">
            <div class="column is-9 is-8-touch">
              <p>
                {" "}
                Hi! My name is Fajar. I started learning about programming while
                pursuing my Mechatronics degree in college.
              </p>
              <p>
                After graduating, I worked as an IT support specialist at a
                company that provides electrical inspection services. In 2020, I
                became a self-employed graphic designer while also taking React
                course from The Odin Project.
              </p>
              <p>
                My non-technical skill includes customer service, negotiation,
                product knowledge, and resource management
              </p>
              <p>
                The technologies I'm familiar with include React, Tailwind CSS,
                Figma, and Jest.
              </p>
            </div>
            <div class="column is-3 is-4-touch">
              {" "}
              <img src={picture} alt="Logo" /> {"  "}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default About;
