import React from "react";

function Hero() {
  return (
    <>
      {" "}
      <section class="hero is-fullheight" id="hero">
        <div class="hero-body animate__animated animate__zoomIn">
          <div class=" container has-text-weight-light has-text-centered">
            <p class=" is-size-1 is-size-3-mobile">Hi, I’m Fajar Putra</p>
            <p class="is-size-5 is-size-6-mobile pb-6">
              I design and build things.
            </p>

            <a href="#about" class="">
              <div class="bottom-chevron ">⌄</div>
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Hero;
