import { useInView } from "react-intersection-observer";
import React, { useEffect } from "react";
import featured1 from "../assets/featured-1.png";
import featured2 from "../assets/featured-2.png";
import githubIcon from "../assets/github-icon.svg";
import visitIcon from "../assets/visit-icon.svg";
import { ReactComponent as GithubIcon } from "../assets/github-icon.svg";
import { ReactComponent as VisitIcon } from "../assets/visit-icon.svg";
import codeIcon from "../assets/code-icon.svg";
import figmaIcon from "../assets/figma-icon.svg";
import gitIcon from "../assets/git-icon.svg";
import pizzaIcon from "../assets/pizza-icon.svg";
function Project() {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    // Update the document title using the browser API
    if (inView === true) {
      document.getElementById("project").classList.remove("hidden");
      document.getElementById("project").classList.add("animate__fadeInLeft");
    }
  });
  return (
    <>
      <section className="project-wrapper">
        {" "}
        <div
          ref={ref}
          className="project hidden animate__animated "
          id="project"
        >
          {" "}
          <div className="featured-project">
            {" "}
            <h1 class="section-header has-text-weight-light">
              ⠀Featured Project⠀
            </h1>
            <div className="featured-content">
              <div className="featured-item columns">
                <div className="featured-details-left column is-6 is-3 ">
                  <p class="is-size-4 is-size-5-mobile has-text-weight-light">
                    <a href="https://alts9.github.io/resume-maker/">
                      Resume Maker
                    </a>
                  </p>
                  <p class="">
                    A quick and easy way to create a professional-looking
                    resume. No account or software required. Just visit, type
                    your info, download as PDF.
                  </p>
                  <ul class=" pt-0-mobile is-size-7">
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>JS</li>
                    <li>Figma</li>
                    <li>React</li>
                  </ul>
                  <div className="icon-container">
                    <a href="https://github.com/alts9/resume-maker">
                      <GithubIcon class="github-icon" />
                    </a>
                    <a href="https://alts9.github.io/resume-maker/">
                      <VisitIcon class="visit-icon" />
                    </a>
                  </div>
                </div>
                <div className="column img-container is-6">
                  <a href="https://alts9.github.io/resume-maker/">
                    <img src={featured1} alt="" /> {"  "}
                  </a>
                </div>
              </div>{" "}
              <div className="featured-item columns">
                {" "}
                <div className="column  img-container is-6">
                  <a href="https://alts9.github.io/Hangman-React/">
                    <img src={featured2} alt="" /> {"  "}
                  </a>
                </div>
                <div className="featured-details-right column is-6 is-3 ">
                  <p class="is-size-4  is-size-5-mobile has-text-weight-light">
                    <a href="https://alts9.github.io/Hangman-React/">
                      Hangman Game
                    </a>
                  </p>
                  <p class="">
                    A word-guessing game where players attempt to guess a hidden
                    word by suggesting letters. Each mistake resulting in the
                    drawing of a part of a stick figure being hanged.
                  </p>
                  <ul class="  is-size-7">
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>JS</li>
                    <li>Figma</li>
                    <li>React</li>
                  </ul>{" "}
                  <div className="icon-container">
                    <a href="https://github.com/alts9/Hangman-React">
                      <GithubIcon class="github-icon" />
                    </a>
                    <a href="https://alts9.github.io/Hangman-React/">
                      <VisitIcon class="visit-icon" />
                    </a>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
          <div className="other-project">
            {" "}
            <h1 class="section-header has-text-weight-light">
              ⠀Other Project⠀
            </h1>
            <div className="columns">
              <div className="column is-6 columns">
                <div className="column is-2">
                  <a href="https://codepen.io/alts9">
                    <img src={codeIcon} alt="" />
                  </a>
                </div>
                <div className="column is-10">
                  {" "}
                  <p class="is-size-4  is-size-5-mobile has-text-weight-light">
                    <a href="https://codepen.io/alts9">Code Fragment</a>
                  </p>
                  <p class="">
                    A collection of single-purpose sample code as experiments
                    before applied to a real project.
                  </p>
                  <ul class=" is-size-7">
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>JS</li>
                    <li>Other</li>
                  </ul>{" "}
                  <div className="icon-container">
                    {" "}
                    <a href="#"></a>
                    <a href="https://codepen.io/alts9">
                      <VisitIcon class="visit-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="column is-6 columns">
                <div className="column is-2">
                  <a href="https://www.figma.com/@alts9">
                    <img src={figmaIcon} alt="" />
                  </a>
                </div>
                <div className="column is-10">
                  {" "}
                  <p class="is-size-4  is-size-5-mobile has-text-weight-light">
                    <a href="https://www.figma.com/@alts9">Figma Design</a>
                  </p>
                  <p class="">A collection of of Figma Design.</p>
                  <ul class="  is-size-7">
                    <li>Figma</li>
                  </ul>{" "}
                  <div className="icon-container">
                    <a href="#"></a>
                    <a href="https://www.figma.com/@alts9">
                      <VisitIcon class="visit-icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-6 columns">
                <div className="column is-2">
                  <a href="https://alts9.github.io/WhichPizzaIsBigger/">
                    <img src={pizzaIcon} alt="" />
                  </a>
                </div>
                <div className="column is-10">
                  {" "}
                  <p class="is-size-4  is-size-5-mobile has-text-weight-light">
                    <a href="https://alts9.github.io/WhichPizzaIsBigger/">
                      Which Pizza Is Bigger?
                    </a>
                  </p>
                  <p class="">
                    Does 2x12" pizza have more pizza than 1x18"? Visit this
                    website to find out.
                  </p>
                  <ul class="  is-size-7">
                    <li>HTML</li>
                    <li>CSS</li>
                    <li>JS</li>
                    <li>Figma</li>
                  </ul>{" "}
                  <div className="icon-container">
                    <a href="https://github.com/alts9/WhichPizzaIsBigger">
                      <GithubIcon class="github-icon" />
                    </a>
                    <a href="https://alts9.github.io/WhichPizzaIsBigger/">
                      <VisitIcon class="visit-icon" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="column is-6 columns">
                <div className="column is-2">
                  <a href="https://drive.google.com/file/d/16svO1FKD-l70mClBeUQohwLyGV2MfuQM/view?usp=share_link">
                    <img src={gitIcon} alt="" />
                  </a>
                </div>
                <div className="column is-10">
                  {" "}
                  <p class="is-size-4  is-size-5-mobile has-text-weight-light">
                    <a href="https://drive.google.com/file/d/16svO1FKD-l70mClBeUQohwLyGV2MfuQM/view?usp=share_link">
                      Git In A Slide
                    </a>
                  </p>
                  <p class="">
                    A visually-oriented Git guide with graphics and flowcharts.
                  </p>
                  <ul class="  is-size-7">
                    <li>PowerPoint</li>
                  </ul>{" "}
                  <div className="icon-container">
                    <a href="#"></a>
                    <a href="https://drive.google.com/file/d/16svO1FKD-l70mClBeUQohwLyGV2MfuQM/view?usp=share_link">
                      <VisitIcon class="visit-icon" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Project;
