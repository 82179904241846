import React from "react";
function NavBar() {
  function toggleMobileNavbar() {
    document.querySelector(".navbar-burger").classList.toggle("is-active");
    document.querySelector(".navbar-menu").classList.toggle("is-active");
  }
  return (
    <>
      <nav
        class="navbar is-white animate__animated animate__fadeInDown "
        role="navigation"
        aria-label="main navigation"
      >
        <div class="navbar-brand">
          <a class="navbar-item " href="#hero">
            <div className="logo">F</div>
          </a>
          <a
            onClick={toggleMobileNavbar}
            role="button"
            class="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div id="navbarBasicExample" class="navbar-menu ">
          <div class="navbar-start">
            <a class="navbar-item" href="#about">
              About
            </a>
            <a class="navbar-item " href="#experience">
              Experience
            </a>
            <a class="navbar-item" href="#project">
              Project
            </a>
            <a class="navbar-item" href="#contact">
              Contact
            </a>
          </div>

          <div class="navbar-end">
            <div class="navbar-item">
              <div class="buttons">
                <a
                  href="https://drive.google.com/file/d/1Rp9RMUs1Izs5W3gEqiU0j-7OIZc66-Ie/view?usp=drive_link"
                  class="button"
                >
                  Resume
                </a>
                <a href="mailto:fajarridalta@gmail.com" class="button">
                  Hire Me
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}

export default NavBar;
