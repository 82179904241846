import React from "react";
import { ReactComponent as GithubIcon } from "../assets/github-icon.svg";
import { ReactComponent as LinkedInIcon } from "../assets/linkedIn-icon.svg";
function Footer() {
  return (
    <>
      <footer class="footer py-4">
        <div class="content has-text-centered">
          <p>
            Fajar Putra - 2023 -
            <a href="https://github.com/alts9/">
              {" "}
              <GithubIcon className="github-icon" />
            </a>
            <a href="https://www.linkedin.com/in/fajar-putra-b27b2290">
              {" "}
              <LinkedInIcon className="linkedIn-icon" />
            </a>
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
