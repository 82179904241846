import "./App.css";
import "animate.css";
import "./Animate.css";
import Navbar from "./components/Navbar";
import Hero from "./components/Hero";
import About from "./components/About";
import Experience from "./components/Experience";
import Project from "./components/Project";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
function App() {
  return (
    <>
      <Navbar />
      <div className="main">
        <Hero />
        <About />
        <Experience />
        <Project />
        <Contact />
        <Footer />
      </div>
    </>
  );
}

export default App;
