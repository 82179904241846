import { useInView } from "react-intersection-observer";
import React, { useEffect } from "react";
function Contact() {
  const { ref, inView } = useInView({
    threshold: 0.2,
    triggerOnce: true,
  });

  useEffect(() => {
    // Update the document title using the browser API
    if (inView === true) {
      document.getElementById("contact").classList.remove("hidden");
      document.getElementById("contact").classList.add("animate__fadeInLeft");
    }
  });
  return (
    <>
      {" "}
      <section
        ref={ref}
        className="contact animate__animated hidden "
        id="contact"
      >
        <h1 class="section-header  has-text-weight-light">⠀Contact⠀</h1>
        <div className="columns">
          <div className="column is-6 is-offset-3">
            <p>
              {" "}
              I am currently looking for a job. If you are interested in hiring
              me, feel free to send me a message.
            </p>
            <a class="button is-responsive" href="mailto:fajarridalta@gmail.com">
              Write Message
            </a>
          </div>
        </div>
      </section>
    </>
  );
}

export default Contact;
